import { takeEvery, call, fork, take, cancel, cancelled } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { chatWebSocketService } from 'services';
import {
  ChatDealerJoinedResponse,
  ClientSideNotifications,
  ErrorNotifications,
  NewChatMessageNotificationResponse,
  NotificationsChat,
} from 'types';

import { ACTIONS } from '../constants';
import { connectToChatWebSocketAction } from '../reducers';
import {
  chatDealerJoined,
  newChatMessage,
  onChatSocketConnectedSaga,
  onChatSocketDisconnectedSaga,
  onChatSocketErrorSaga,
} from './notificationHandlers';
import {
  createChatWebSocketChannel,
  errorChatWebSocketChannel,
  simulateChatWebSocketChannel,
} from './channels';

function* listenChatDealerJoinedMessage(): any {
  // @ts-ignore
  const notificationChannel = yield call(createChatWebSocketChannel, [
    NotificationsChat.CHAT_DEALER_CONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: ChatDealerJoinedResponse) {
      yield call(chatDealerJoined, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenNewMessageInChatMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createChatWebSocketChannel, [
    NotificationsChat.NEW_CHAT_MESSAGE,
  ]);

  try {
    yield takeEvery(
      notificationChannel,
      function* (notification: NewChatMessageNotificationResponse) {
        yield call(newChatMessage, notification);
      },
    );
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenChatSocketConnectedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(simulateChatWebSocketChannel, [
    ClientSideNotifications.SOCKET_CONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onChatSocketConnectedSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenChatSocketDisconnectedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(simulateChatWebSocketChannel, [
    ClientSideNotifications.SOCKET_DISCONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onChatSocketDisconnectedSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenChatSocketErrorMessage() {
  // @ts-ignore
  const notificationChannel = yield call(errorChatWebSocketChannel, [
    ErrorNotifications.CONNECT_ERROR,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onChatSocketErrorSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

export function* connectToChatWebsocket({
  payload: { token },
}: ActionType<typeof connectToChatWebSocketAction>) {
  const isConnecting = chatWebSocketService.isChatConnecting();
  const isConnected = chatWebSocketService.isConnected();
  // @ts-ignore
  if (!isConnected && !isConnecting && token) {
    yield call(() => chatWebSocketService.connect(token));

    // @ts-ignore
    const taskChatDealerConnected = yield fork(listenChatDealerJoinedMessage);
    // @ts-ignore
    const taskNewChatMessage = yield fork(listenNewMessageInChatMessage);
    // @ts-ignore
    const taskChatSocketConnected = yield fork(listenChatSocketConnectedMessage);
    // @ts-ignore
    const taskChatSocketDisconnected = yield fork(listenChatSocketDisconnectedMessage);
    // @ts-ignore
    const taskChatSocketError = yield fork(listenChatSocketErrorMessage);

    yield take(ACTIONS.CLEAR_SAGA_CHANNELS);
    yield call(() => chatWebSocketService.disconnect());
    yield cancel(taskChatDealerConnected);
    yield cancel(taskNewChatMessage);
    yield cancel(taskChatSocketConnected);
    yield cancel(taskChatSocketDisconnected);
    yield cancel(taskChatSocketError);
  }
}
